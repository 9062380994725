.react-datepicker-wrapper input {
  width: 100%;
  border-width: 0 0 2px 0;
  border-color: #CACACA;
  background: transparent;
  color: #5d5e60;
  font-size: 1rem;
  font-family: 'D-din';
  font-weight: 400;
  outline: none!important;
  padding: 4px 0 10px;
}
.react-datepicker-wrapper input:focus,
.react-datepicker-wrapper input:focus-visible {
  outline: none!important;
  border-color: #f1b80e!important;
  box-shadow: none!important;
}
.react-datepicker-wrapper input::placeholder {
  color: #6c757d;
}
.swal2-container {
  z-index: 10000!important;
}

.link-ct {
  color: #5C5D60; 
}

.pieChart rect {
  fill: #fafafa;
}

.input-mapprisk span:after {
  border-bottom: 2px solid #43a491;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}
.input-mapprisk span:before {
border-bottom: 2px solid #CACACA;
left: 0;
bottom: 0;
content: "\00a0";
position: absolute;
right: 0;
-webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
pointer-events: none;
}

.input-mapprisk input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: border-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 15.765px 16px 15.765px 0!important;
  outline: none!important;
  margin-top: 1px;
}
.input-mapprisk.error span:after, .input-mapprisk.error span:before {
  border-bottom-color: #d32f2f;
}

.input-mapprisk.error input::placeholder,
.input-mapprisk.error label {
  color: #d32f2f;
}


.input-mapprisk input:focus + span:after {
  -webkit-transform: scaleX(1) translateX(0);
  -moz-transform: scaleX(1) translateX(0);
  -ms-transform: scaleX(1) translateX(0);
  transform: scaleX(1) translateX(0);
}

